<template>
   <room :node="node" />
</template>
<script>
import { nodes } from '../../config';
import Room from '../components/Room.vue';
export default {
   data: () => ({
      node: nodes.find(x => x.name == 'Dapur')
   }),
   components: { Room }
}
</script>